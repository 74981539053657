@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom CSS */
.navbar {
  background-color: rgb(143, 29, 20);
}

.sidebar-layout {
  @apply shadow-md bg-gray-600 px-1 ease-in-out duration-300;
  height: calc(100vh - 75px);
}

.main-content-layout {
  @apply w-full shadow-md ease-in-out duration-300;
  height: calc(100vh - 75px);
}

.us-page {
  @apply bg-gray-100;
  height: inherit;
}

.home-page {
  @apply p-2 flex justify-center bg-gray-100;
  height: inherit;
}

.search-page {
  @apply p-2 bg-gray-100;
  height: inherit;
}

.sidebar-item {
  @apply flex items-center text-sm px-2 py-4 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-red-500 transition duration-300 ease-in-out;
}

.product-card:hover {
  box-shadow: 2px 2px 6px 2px rgb(161, 161, 161);
}
